<footer>
  <div class="bg-page-background text-page-foreground px-4 py-16 flex flex-col items-center gap-16">
    <h2 class="text-3xl font-black text-center">Friends of Bongo’s Bingo</h2>

    <div class="flex flex-row flex-wrap gap-20 md:gap-28 items-center justify-center [&>*]:h-16 md:[&>*]:h-24">
      <img src="assets/images/hooch.svg" alt="Hooch" />
      <img src="assets/images/southern-comfort.svg" alt="Southern Comfort" />
    </div>
  </div>

  <div class="bg-primary">
    <div class="px-4 py-16 flex flex-col items-center gap-8 border-b-2 border-black">
      <img src="assets/images/icon.svg" class="h-16" alt="Bongo's Bingo" />

      <div class="flex flex-col md:flex-row items-center gap-14 [&>a]:text-2xl [&>a]:font-bold [&>a]:text-white">
        <a>Link 1</a>
        <a>Link 2</a>
        <a>Link 3</a>
      </div>
    </div>

    <div class="px-4 py-16 border-b-2 border-black">
      <app-newsletter></app-newsletter>
    </div>

    <div class="px-4 py-16 relative overflow-hidden">
      <img
        class="absolute w-52 -top-4 right-[70vw] md:right-[80vw] md:top-auto z-0"
        src="assets/images/unicorn.svg"
        alt=""
      />
      <img
        class="absolute w-52 top-72 left-[60vw] md:left-[80vw] md:top-auto z-0"
        src="assets/images/duck.svg"
        alt=""
      />

      <div class="flex flex-col items-center gap-24">
        <div id="app-banner" class="w-full max-w-3xl p-8 hidden md:block z-10">
          <div class="flex flex-row gap-6">
            <div class="h-40 w-40 bg-white flex-shrink-0"></div>
            <p class="text-3xl text-white font-normal">Scan to download App on the Playstore and Appstore.</p>
          </div>
        </div>

        <div id="app-links" class="md:hidden flex flex-col gap-4 py-8">
          <h2 class="text-3xl font-black text-center text-white">Download Our App</h2>

          <img src="assets/images/googleplay.svg" alt="Google Play" />
          <img src="assets/images/appstore.svg" alt="App Store" />
        </div>

        <div class="flex flex-col items-center gap-12">
          <div class="flex md:flex-row flex-col items-center gap-14">
            <img src="assets/images/gamble-aware.png" alt="Gamble Aware" />
            <img src="assets/images/drink-aware.png" alt="Drink Aware" />
            <img src="assets/images/gambling-commission.png" alt="Gambling Commission" />
          </div>

          <nav
            class="flex flex-col md:flex-row flex-wrap items-center justify-center gap-14 [&>a]:font-normal [&>a]:text-xl [&>a]:text-white [&>a]:text-center"
          >
            <a>How to Play</a>
            <a>Terms & Conditions</a>
            <a>Corporation Terms & Conditions</a>
            <a>Sales & Refunds</a>
            <a>Responsible Play</a>
            <a>Self Exclusion</a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</footer>
