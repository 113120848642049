<section class="bg-repeat bg-cover bongos-background" aria-label="Subscribe to the new events mailing list">
  <form [formGroup]="newsletterForm" (ngSubmit)="subscribeToNewsletter()">
    <fieldset [disabled]="isBusy()">
      <div class="px-5 py-8 md:px-36 md:py-20 w-full max-w-screen-2xl">
        <h2 class="text-3xl font-black text-center text-white">Join our newsletter</h2>

        <div class="flex flex-col md:flex-row md:flex-wrap justify-center items-start mt-8 gap-3">
          <div class="w-full md:w-auto md:min-w-64 md:flex-grow pt-1 md:pt-0">
            <app-form-text-field
              controlId="email"
              formControlName="email"
              placeholder="Your email"
              background="default"
              height="64px"
            ></app-form-text-field>
            <app-form-validation-errors
              [control]="newsletterForm.get('email')"
              [validationErrors]="{ required: 'Email is required', email: 'Email must be a valid email address' }"
            ></app-form-validation-errors>
          </div>

          <div class="flex flex-col md:flex-row items-center w-full md:w-auto">
            <button
              class="white-button min-h-16 rounded-md w-full md:min-w-80 md:w-80 flex items-center justify-center"
              [disabled]="(newsletterForm.invalid && newsletterForm.pristine) || isSubmitted()"
            >
              @if (isBusy()) {
                <app-spinner></app-spinner>
              } @else {
                <span class="px-4 font-bold">Subscribe to mailing list</span>
              }
            </button>

            @if (isSubscribed() && newsletterForm.valid && newsletterForm.pristine) {
              <app-static-notification
                notificationType="success"
                class="md:pl-8 pt-4 md:pt-0 md:min-h-16 flex md:h-full w-full"
              >
                <span>You have been subscribed to the newsletter</span>
              </app-static-notification>
            }
          </div>
        </div>
      </div>
    </fieldset>
  </form>

  <div class="flex justify-center px-5 w-full max-w-screen-2xl">
    <button
      class="icon-button focus:outline-none"
      (click)="visitPage('facebook')"
      aria-label="Share on Facebook"
      tabindex="0"
    >
      <i class="fab fa-facebook text-white text-2xl"></i>
    </button>

    <button
      class="icon-button focus:outline-none"
      (click)="visitPage('instagram')"
      aria-label="Share on Instagram"
      tabindex="1"
    >
      <i class="fab fa-instagram text-white text-2xl"></i>
    </button>

    <button
      class="icon-button focus:outline-none"
      (click)="visitPage('email')"
      aria-label="Share via Email"
      tabindex="2"
    >
      <i class="fas fa-envelope text-white text-2xl"></i>
    </button>

    <button
      class="icon-button focus:outline-none"
      (click)="visitPage('youtube')"
      aria-label="Share on YouTube"
      tabindex="3"
    >
      <i class="fab fa-youtube text-white text-2xl"></i>
    </button>

    <button
      class="icon-button focus:outline-none"
      (click)="visitPage('tiktok')"
      aria-label="Share on TikTok"
      tabindex="4"
    >
      <i class="fab fa-tiktok text-white text-2xl"></i>
    </button>
  </div>
</section>
