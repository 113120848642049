import { CommonModule } from '@angular/common';
import { Component, forwardRef, input, output, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorBase } from '../control-value-accessor-base/control-value-accessor.base';
import { FormTextFieldComponent } from '../form-text-field/form-text-field.component';

@Component({
  selector: 'app-form-password-field',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormTextFieldComponent],
  templateUrl: './form-password-field.component.html',
  styleUrl: './form-password-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPasswordFieldComponent),
      multi: true,
    },
  ],
})
export class FormPasswordFieldComponent extends ControlValueAccessorBase<string> {
  controlId = input.required<string>();
  iconButton = input<string>();
  required = input<boolean>(false);

  onIconButtonClicked = output<void>();

  protected isPasswordVisible = signal<boolean>(false);

  protected onChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.value = value;
    this.change(value);
  }

  protected togglePasswordVisibility() {
    this.isPasswordVisible.update((value) => !value);
  }
}
