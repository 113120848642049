import { CommonModule } from '@angular/common';
import { Component, computed, forwardRef, input, output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorBase } from '../control-value-accessor-base/control-value-accessor.base';

export type BackgroundColor = 'default' | 'black';

@Component({
  selector: 'app-form-text-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './form-text-field.component.html',
  styleUrl: './form-text-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextFieldComponent),
      multi: true,
    },
  ],
})
export class FormTextFieldComponent extends ControlValueAccessorBase<string> {
  controlId = input.required<string>();
  type = input<string>('text');
  placeholder = input<string>('');
  iconButton = input<string>();
  required = input<boolean>(false);
  background = input<BackgroundColor>('default');
  height = input<string>();

  onIconButtonClicked = output<void>();

  protected onChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.change(input.value);
  }

  protected iconButtonClick() {
    this.onIconButtonClicked.emit();
  }

  backgroundClasses = computed(() => {
    return this.background() === 'default'
      ? 'bg-form-field-color bg-opacity-10 border border-white focus:border-white hover:border-white active:border-white placeholder-white'
      : 'bg-black';
  });
}
