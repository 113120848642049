<div class="flex items-center justify-center relative">
  <input
    [id]="controlId()"
    [type]="isPasswordVisible() ? 'text' : 'password'"
    [attr.required]="required()"
    class="flex-grow h-12 w-full p-2 border rounded-md bg-form-field-color bg-opacity-10 text-form-field-color focus:border-primary focus:outline-none border-gray-300"
    [(ngModel)]="value"
    (input)="onChange($event)"
    (blur)="blur()"
    [disabled]="isDisabled()"
  />
  <button
    type="button"
    class="icon-button text-form-field-label px-1 absolute right-1"
    aria-label="Toggle password visibility"
    (click)="togglePasswordVisibility()"
    [disabled]="isDisabled()"
  >
    <i class="fa-regular fa-eye{{ isPasswordVisible() ? '-slash' : '' }}"></i>
  </button>
</div>
