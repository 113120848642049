import { CommonModule, KeyValue } from '@angular/common';
import { Component, input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-validation-errors',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-validation-errors.component.html',
  styleUrl: './form-validation-errors.component.scss',
})
export class FormValidationErrorsComponent {
  control = input.required<AbstractControl | null>();
  validationErrors = input.required<Record<string, string>>();

  protected getErrorText(error: KeyValue<string, string>) {
    return this.validationErrors()[error.key] ?? error.value;
  }
}
