import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function postcodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const postcodeRegex =
      /^([GIR]\s?0[A]{2}|((([A-Z]{1,2}[0-9]{1,2})|(([A-Z]{1,2}[0-9])|(([A-Z]{1,2}[0-9][A-Z])|(([A-Z]{1,2}[0-9]{1,2}[A-Z])|([A-Z]{1,2}[0-9]{1,2}))))))\s?[0-9][A-Z]{2})$/;
    return postcodeRegex.test(value) ? null : { postcode: true };
  };
}
