<div class="flex items-center justify-center relative">
  <input
    [id]="controlId()"
    [type]="type()"
    [placeholder]="placeholder()"
    [attr.required]="required()"
    class="flex-grow h-12 w-full p-2 border rounded-md placeholder:text-sm {{
      backgroundClasses()
    }} text-form-field-color focus:border-primary focus:outline-none border-form-field-border"
    [(ngModel)]="value"
    (input)="onChange($event)"
    (blur)="blur()"
    [disabled]="isDisabled()"
    [style.height]="height()"
    autocomplete="off"
  />
  @if (iconButton(); as icon) {
    <button
      type="button"
      class="icon-button text-white px-1 absolute right-1"
      [disabled]="isDisabled()"
      (click)="iconButtonClick()"
    >
      <i class="{{ icon }}"></i>
    </button>
  }
</div>
