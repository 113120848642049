<label class="flex flex-row items-center gap-4">
  <input
    type="checkbox"
    [attr.required]="required()"
    [(ngModel)]="value"
    (input)="onChange($event)"
    (blur)="blur()"
    class="checkbox"
    [disabled]="isDisabled()"
  />
  <div class="text-xs">
    <ng-content></ng-content>
  </div>
</label>
