export class DateUtil {
  static parseDate(dateString: string): string | null {
    const birthDate = new Date(dateString);

    if (isNaN(birthDate.getTime())) {
      const parts = dateString.split('-');
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
        const paddedMonth = String(month).padStart(2, '0');
        const paddedDay = String(day).padStart(2, '0');
        const bday = `${year}-${paddedMonth}-${paddedDay}`;
        return bday;
      }
      return birthDate.toDateString();
    }
    return birthDate.toDateString();
  }
}
