<div class="relative inline-block w-16 h-8 align-middle select-none transition duration-200 ease-in">
  <input
    type="checkbox"
    [id]="controlId()"
    class="toggle-checkbox hidden"
    [(ngModel)]="value"
    (input)="onChange($event)"
    (blur)="blur()"
  />

  <label
    [for]="controlId()"
    class="toggle-label block overflow-hidden h-8 rounded-full cursor-pointer bg-white bg-opacity-30 focus:outline-none focus:border focus:border-primary"
    tabindex="0"
    (keydown)="onKeyDown($event)"
    [attr.aria-checked]="value ? 'true' : 'false'"
    role="switch"
    [attr.aria-label]="ariaLabel()"
  >
    <span
      class="toggle-span absolute block rounded-full transition-transform duration-200 ease-in-out"
      [ngClass]="{ 'translate-x-full bg-primary': value, 'bg-white bg-opacity-20': !value }"
    ></span>
  </label>
</div>
