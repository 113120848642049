import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();

    if (!value) {
      return null;
    }

    const phoneRegex = /^(?:0|\+44)\s?\d{1,5}\s?\d{3,4}\s?\d{3,4}$/;

    return phoneRegex.test(value) ? null : { phoneNumber: true };
  };
}
