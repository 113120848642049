<input
  [id]="controlId()"
  type="date"
  [attr.required]="required()"
  class="h-12 w-full p-2 border rounded-md bg-form-field-color bg-opacity-10 text-form-field-color focus:border-primary focus:outline-none"
  [(ngModel)]="value"
  (input)="onChange($event)"
  (blur)="blur()"
  [disabled]="isDisabled()"
/>
