import { CommonModule } from '@angular/common';
import { Component, forwardRef, input, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorBase } from '../control-value-accessor-base/control-value-accessor.base';

@Component({
  selector: 'app-form-checkbox-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './form-checkbox-field.component.html',
  styleUrl: './form-checkbox-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxFieldComponent),
      multi: true,
    },
  ],
})
export class FormCheckboxFieldComponent extends ControlValueAccessorBase<boolean> {
  controlId = input.required<string>();
  required = input<boolean>(false);

  protected checked = signal(false);

  protected onChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.toggleChecked();
    this.change(input.checked);
  }

  private toggleChecked() {
    this.checked.update((value) => !value);
  }
}
